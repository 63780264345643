<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <div :class="'single-logo ' + logoClass">
      <dynamic-template name="Logo"></dynamic-template>
    </div>
    <div class="brokenFooterDiv">
      <dynamic-template name="FooterBar"></dynamic-template>
    </div>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout12',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    },
    logoClass() {
      return this.$store.state.logoPosition || 'bottom-right';
    }
  }
};
</script>

<style lang="scss">
.brokenFooterDiv {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 55px;

  >footer {
    position: absolute !important;

    .right-col {
      padding-right: 60px !important;
    }
  }
}

.single-logo {
  position: fixed;
  margin: 36px;

  img {
    width: 180px;
    height: auto;
  }

  &.left-top {
    left: 30px;
    top: 40px;
  }

  &.left-bottom {
    left: 30px;
    bottom: 75px;
  }

  &.right-top {
    right: 30px;
    top: 40px;
  }

  &.right-bottom {
    right: 30px;
    bottom: 75px;
  }
}
</style>
